import { useState, useEffect} from "react";
import './App.css';
import './styles.css';
import { useChat } from "./contexts/ChatProvider";
import {WidgetContainer} from "./components/WidgetContainer";
import {nanoid} from "nanoid";
import './url-search-params-polyfill'; // Import the polyfill

function App() {
  const {
    sendMessage,
    setCustomTheme,
    setCustomColorSet,
    fetchConversations,
    messages,
    loadConversation,
    setLoadConversation,
    setGreeting,
    setClientKey,
    setDataPrefix,
    setHeader,
    setUserId,
    setMemberId,
  } = useChat();
  const [license, setLicense] = useState("");

  useEffect( () => {
    // Signal that the content is ready
    window.parent.postMessage({ type: 'contentReady' }, '*');
    const urlParams = new URLSearchParams( window.location.search);
    const clientKey = urlParams.get("clientKey");
    setClientKey(clientKey);
    const dataPrefix = urlParams.get("dataPrefix");
    setDataPrefix(dataPrefix);
    const header = urlParams.get("chatHeader");
    setHeader(header);
    const license = urlParams.get("license");
    setLicense(license);
  },[]);

  useEffect(() => {
    const handleMessage = evt => {
      if(evt.data.theme ) {
        setCustomTheme(evt.data.theme);
      }
      if(evt.data.updateConfig ) {
        if(evt.data.updateConfig.theme){
          setCustomTheme(evt.data.updateConfig.theme);
        }
        if(evt.data.updateConfig.colorSet){
          setCustomColorSet(evt.data.updateConfig.colorSet);
        }
      }

      if(evt.data.updateIds) {
        if(evt.data.updateIds.userId){
          setUserId(evt.data.updateIds.userId);
        }
        if(evt.data.updateIds.memberId){
          setMemberId(evt.data.updateIds.memberId);
        }
        window.parent.postMessage({ type: 'updateIdsOk' }, '*');
      }
      else if(evt.data.greeting) {
        setGreeting(evt.data.greeting);
      }
      else if(evt.data.load){
        if(!loadConversation){
          setLoadConversation(true);
        }
      } 
      else if(evt.data.sendMessage) {
        sendMessage({
          _id: nanoid(),
          text: evt.data.sendMessage,
          user: "remote",
          type: "outgoing",
        });
      }
    };

    window.addEventListener("message", handleMessage);

    return () => window.removeEventListener("message", handleMessage);

  },[setGreeting, sendMessage, setCustomTheme, setCustomColorSet, fetchConversations]);

  return (
      <WidgetContainer license={license}/>
  );
}

export default App;